<template>
  <div class="tw-px-6">
    <v-row>
      <v-col cols="12" class="tw-mt-8">
        <p
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-text-dark-green tw--mt-11"
        >
          Suggest a stay
        </p>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <v-form @submit.prevent="save">
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.linkUrl"
              rules="max:500"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.linkUrl"
                type="text"
                :error-messages="errors"
                label="Link to stay"
                sublabel="Drop a link and we'll fill in the details"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <p class="j-text-field-label tw-mt-6 tw-mb-2">
              Explore booking options
            </p>
            <p class="tw-text-left tw-text-xs tw-text-light-grey tw-mb-6">
              We earn a small amount when you buy through our links
            </p>
            <div class="tw-flex">
              <a
                href="https://prf.hn/click/camref:1101l3BN3k/pubref:letsjetty/ar:app"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-w-1/2 tw-mr-2"
              >
                <button
                  class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
                  type="button"
                >
                  <span class="tw-text-left tw-text-dark-green tw-font-bold"
                    >Expedia</span
                  >
                  <img
                    class="tw-rounded-full"
                    src="@/assets/svgs/arrow-right-dark-background.svg"
                    alt="Go"
                  />
                </button>
              </a>
              <a
                href="https://prf.hn/click/camref:1100l3BNe3/pubref:letsjetty/ar:app"
                target="_blank"
                rel="noopener noreferrer"
                class="tw-w-1/2 tw-ml-2"
              >
                <button
                  class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
                  type="button"
                >
                  <span class="tw-text-left tw-text-dark-green tw-font-bold"
                    >VRBO</span
                  >
                  <img
                    class="tw-rounded-full"
                    src="@/assets/svgs/arrow-right-dark-background.svg"
                    alt="Go"
                  />
                </button>
              </a>
            </div>

            <validation-provider
              v-slot="{ errors }"
              name="accommodation.location"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.location"
                type="text"
                :error-messages="errors"
                label="Location*"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <div class="tw-mt-6 tw-relative">
              <validation-provider
                v-slot="{ errors }"
                name="accommodation.price"
                rules="required"
              >
                <j-text-field
                  color="secondary"
                  v-model="accommodation.price"
                  type="text"
                  :error-messages="errors"
                  label="Cost*"
                  customClasses="tw-text-left"
                />
              </validation-provider>
              <div class="tw-absolute tw--top-1 tw-right-2">
                <span
                  :class="[
                    'tw-text-xs',
                    'tw-mr-4',
                    'tw-cursor-pointer',
                    'tw-font-semibold',
                    'tw-border-black',
                    {
                      'tw-border-b-2':
                        accommodation.priceType === price_types.PER_NIGHT
                    }
                  ]"
                  @click="accommodation.priceType = price_types.PER_NIGHT"
                  >per night</span
                >
                <span
                  :class="[
                    'tw-text-xs',
                    'tw-cursor-pointer',
                    'tw-font-semibold',
                    'tw-border-black',
                    {
                      'tw-border-b-2':
                        accommodation.priceType === price_types.TOTAL
                    }
                  ]"
                  @click="accommodation.priceType = price_types.TOTAL"
                  >total</span
                >
              </div>
            </div>
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.name"
              rules="required"
            >
              <j-text-field
                color="secondary"
                v-model="accommodation.name"
                type="text"
                :error-messages="errors"
                label="Name of Stay*"
                class="tw-mt-6"
                customClasses="tw-text-left"
              />
            </validation-provider>
            <validation-provider
              v-slot="{ errors }"
              name="accommodation.text"
              rules="required|max:500"
            >
              <j-textarea
                v-model="accommodation.text"
                class="tw-mt-6"
                outlined
                :error-messages="errors"
                background-color="#fafafa"
                label="Post Message*"
                sublabel="Tell the crew why they'd love this stay."
                placeholder="Look at this place! It has enough beds for all of us and it's close to town. What do you think..."
              />
            </validation-provider>
            <span class="tw-hidden">{{ invalid }}</span>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { PRICE_TYPES } from "@/enums/price-types.js";
import { mapMutations } from "vuex";
export default {
  name: "AccommodationEdit",
  data() {
    return {
      accommodation: {
        name: "",
        tripId: this.$route.params.id,
        destinationId: this.trip ? this.trip.destinations[0].id : null,
        type: "HOTEL",
        priceType: PRICE_TYPES.TOTAL,
        text: "",
        authorId: this.$store.state.auth.user.id,
        linkUrl: "",
        price: 0,
        location: ""
      },
      price_types: PRICE_TYPES
    };
  },
  watch: {
    accommodation: {
      handler: function () {
        this.$nextTick(() => {
          this.accommodation.price = this.accommodation.price
            .toString()
            .replace(/\D/g, "");
          let temp = this.accommodation.price.toString().replace(/\D/g, "");
          this.accommodation.price = temp;
        });
      },
      deep: true
    },
    enable: function (val) {
      if (val) {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: false
        });
      } else {
        this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
          isDisabled: true
        });
      }
    }
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    enable() {
      return (
        this.accommodation.text.length > 0 && this.accommodation.name.length > 0
      );
    }
  },
  methods: {
    ...mapMutations(["meta/SET_NAVIGATION_BUTTON"]),
    setNavigationButton(options) {
      this["meta/SET_NAVIGATION_BUTTON"](options);
    },
    goTo(route) {
      this.$router.push(route);
    },
    async save() {
      this.setNavigationButton({ text: "Saving" });
      let strippedPrice = 0;
      if (this.accommodation.price && this.accommodation.price > 0) {
        strippedPrice =
          parseInt(this.accommodation.price.toString().replace(/\D/g, "")) *
          100;
      }

      try {
        await this.$store.dispatch("post-accommodation/patch", [
          this.$route.params.uuid,
          {
            ...this.accommodation,
            price: strippedPrice
          }
        ]);
        setTimeout(() => {
          this.setNavigationButton({ text: "Save" });
          this.$router.push({
            name: "AccommodationList",
            params: { id: this.$route.params.id }
          });
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    }
  },
  async beforeMount() {
    try {
      const accommodation = await this.$store.dispatch(
        "post-accommodation/find",
        {
          query: {
            uuid: this.$route.params.uuid
          }
        }
      );
      if (accommodation.total > 0) {
        this.accommodation = accommodation.data[0];
        this.accommodation.price = this.accommodation.price / 100;
      } else {
        this.goTo({
          name: "AccommodationList"
        });
      }
    } catch {
      this.goTo({
        name: "AccommodationList"
      });
    }

    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Places To Stay",
      bgColor: "#D6BCFF",
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: true,
      text: "Save",
      isDisabled: true,
      method: this.save,
      bgColor: "tw-bg-chartreuse"
    });
  }
};
</script>
